import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  randomUrl() {
    const randomNum = Math.floor(Math.random() * Math.floor(1000))
    return `https://source.unsplash.com/random/300x200?nature,technology&sig=${randomNum}`
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug

          return (
            <article
              key={node.fields.slug}
              className="flex flex-row mb-5 pt-10"
            >
              {/* <div className="w-1/3"> */}
              {/*   <img src={this.randomUrl()} /> */}
              {/* </div> */}
              <div className="flex flex-col justify-start items-start">
                <Link to={node.fields.slug}>
                  <h3 className="text-4xl font-black text-gray-900 shadow-none mb-1">
                    {title}
                  </h3>
                  <small className="block text-sm text-gray-600 mb-3">
                    {node.frontmatter.date}
                  </small>
                  <p
                    className="text-xl text-gray-800"
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </Link>
              </div>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
